#panel {
    z-index: 11;
    width: 100%;
    min-height: 200px;
    position: fixed;
    bottom: -100%;
    max-height: 0;
    background-color: white;
    overflow-x: hidden;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition: 0.3s;

        &.visible {
            max-height: 64%;
            bottom: 0;
        }
        h1 {
            padding-top: 0;
            margin-top: 32px;
            margin-bottom: 24px;
        }
        .close-btn {
            top: 32px;
        }

    @media only screen and (min-width: 1040px) {
        height: 100%;
        max-height: unset;
        width: 375px;
        position: fixed;
        top: 0;
        left: -375px;
        background-color: white;
        overflow-x: hidden;
        transition: left 0.5s;
        z-index: 10;
        border-radius: 0;
        &.visible {
            left: 0;
            max-height: unset;
        }
    }

    .close-btn {
        position: absolute;
        top: 56px;
        right: 26px;
        padding: 5px 5px;
        font-size: 18px;
        line-height: 20px;
        font-weight: bold;
        color: #54a6f0;
    }
    h1 {
        padding-left: 20px;
        padding-top: 40px;
        font-size: 24px;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 20px;
    }
    li { border-bottom: #E5E8F0 1px solid; }

    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .currencies {
        li {
            height: 72px;
            display: flex;
            align-items: center;
        }
        img {
            margin-right: 8px;
            width: 32px;
            height: 32px;
        }
        .cur-code {
            font-weight: 500;
            font-size: 16px;
        }
        .cur-description {
            color: #7d828e;
            font-size: 14px;
        }
    }

    .checkbox-slider-wrapper {
        width: 51px;
        height: 31px;
        margin-left: auto;
        margin-right: 20px;
        position: relative;

        .checkbox-status {
            display: block;
            height: 100%;
            background-color: #e7e7e9;
            border-radius: 30px;
            position: relative;
        }

        .checkbox-status::after {
            content: '';
            display: block;
            width: 27px;
            height: 27px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0 1px 5px rgba(0,0,0,0.3);
            position: absolute;
            left: 2px;
            top: 2px;
            transition: all 0.2s ease;
        }

        input {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: 0;
            opacity: 0;
            cursor: pointer;
            -webkit-tap-highlight-color: transparent;

            &:checked + .checkbox-status {
                background-color: #0abc8a;
                &::after {
                    left: 22px;
                }
            }

        }

    }

    .categories {
        margin-top: 8px;
        li {
            height: 64px;
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: 500;
            font-size: 18px;
            min-width: 200px;
            &:hover {
                color: #0AC18E;
            }
            &.selected {
                justify-content: space-between;
            }
            .clear-btn {
                margin-right: 25px;
                background-image: url(../../public/img/cross-red-circle.svg);
                background-repeat: no-repeat;
                cursor: pointer;
                width: 24px;
                height: 24px;
            }
            &.inactive {
                color: #E0E0E0;
                cursor: default;
            }
        }
    }

    .text-btn {
        border: none;
        background-color: inherit;
        cursor: pointer;
        display: inline-block;
        outline: none;
    }
}

.circle-ripple {
    transition: all 0.8s ease;
    background-position: center;
    &:hover {
        background: rgba(255, 255, 255, 0.15) radial-gradient(circle, rgb(255, 255, 255) 1%, transparent 1%) center/15000%;
    }
    &:active {
        background-color: rgb(190, 190, 190);
        background-size: 10%;
        transition: background 0s;
    }
}
