html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

#map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

#map-canvas, #root, .app {
    height: 100%;
    width: 100%;
}

.gm-style {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
    background-color: white;
}

.gm-style .gm-style-iw-d {
    overflow: auto !important;
}

.gm-style .gm-style-iw-c {
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    padding-top: 0 !important;
    padding-left: 0 !important;
}

.gm-style .gm-style-iw-t::after {
    height: 0;
}

.gm-ui-hover-effect {
    display: none !important;
    top: 4px !important;
    right: 4px !important;
}

.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    bottom: 160px !important;
}

.info-window {
    font-weight: 400;
    padding: 50px 0 40px;

    .fields-wrapper {
        padding: 0 20px;
    }

    .logo {
        width: 170px;
        height: 24px;
        margin-bottom: 93px;
        
        @media only screen and (max-width: 1039px) {
            display: none;
        }
    }

    h3 {
        margin: 0 80px 12px 0;
        font-size: 24px;
        //font-weight: 600;
        word-wrap: break-word;
    }

    address, div {
        font-style: normal;
        font-size: 16px;
        line-height: 28px;
        color: #3b3f59;
    }

    a {
        color: rgb(10, 193, 142);
        font-weight: 600;
        display: inline-block;
        line-height: 20px;
        text-decoration: none;

        &:hover {
            opacity: 0.8;
        }
    }

    .currencies-header {
        text-transform: uppercase;
        color: #c8cdd8;
        margin-bottom: 2px;
        font-size: 12px;
        font-weight: bold;
    }

    .report-button {
        float: right;
        color: #b5bac6;

        &::before {
            content: '';
            display: inline-block;
            vertical-align: text-top;
            width: 10px;
            height: 12px;
            margin-right: 7px;
            background: url(../../public/img/icon-report.svg) no-repeat 0 50%;
            background-size: cover;
        }
    }

    .online-assisted-badge {
        padding: 6px 17px;
        border-radius: 20px;
        font-size: 16px;
        background-color: #469cfd;
        color: #fff;
        margin: 24px 0 0 0;
    }
    .instructions {
        font-size: 13px;
        line-height: 23px;
        margin-top: 16px;
        color: #4b4f59;
        letter-spacing: -0.325px;
    }
}

.info-window-status {
    display: block;
    margin: 20px 0;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: 700;
}

.currency {
    display: flex;
    margin-right: 6px;
    align-items: center;

    span {
        font-size: 16px;
    }
}

.currency-list-wrapper{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
}

.cta {
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    margin: 30px 0 0;

    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
        display: none;
    }

    @media only screen and (max-width: 670px) and (orientation: portrait) {        
        white-space: nowrap;
    }
}

.cta a {
    flex: 1;
    display: inline-flex;
    padding: 2px 10px 0 10px;
    height:  38px;
    margin: 0 8px 0 0;
    border: 1px solid #e2e4e8;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(19, 23, 32, 0.08296);
    color: #131720;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    justify-content: center;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    vertical-align: middle;
    align-items: center;
}

.cta .cta-button {
    margin-bottom: 16px;
}

.cta .cta-button:not(:last-child) {
    margin-right: 24px;
}

.cta .directions-button-white,
.cta .directions-button-green {
    width: 121px;
}
.cta .directions-button-white::before,
.cta .directions-button-green::before  {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 28px;
    height: 28px;
    margin-right: 7px;
    margin-bottom: 3px;
    background: url(../../public/img/directions-green.svg) no-repeat 0 50%;
    background-size: cover;
}

.cta .directions-button-green {
    background-color: #0AC18E;
    border-color: #0AC18E;
    color: white;
}

.cta .directions-button-green::before {
    background: url(../../public/img/directions-white.svg) no-repeat 0 50%;
}

.cta .buy-online-btn {
    background-color: #0AC18E;
    min-width: 133px;
    color: white;
    border: 1px solid #03A377;
}

.cta .web-btn {
    border: 1px solid #E2E4E8;
    padding-right: 20px;
    padding-left: 16px;
    // padding: 10px 20px 10px 16px;
    line-height: 1;
}

.cta .buy-online-btn::before,
.cta .web-btn::before {
    content: '';
    display: inline-block;
    vertical-align: bottom;
    width: 25px;
    height: 25px;
    margin-right: 7px;
    background: url(../../public/img/icon-globe.svg) no-repeat 0 50%;
    background-size: cover;
    margin-bottom: 3px;
}

.cta .web-btn::before {
    filter: invert(69%) sepia(64%) saturate(5394%) hue-rotate(127deg) brightness(98%) contrast(101%);
}

.cta .buy-online-btn::before {
    filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
}

.info-phone {
    width: 75px;
    margin-right: 30px;
}

.info-phone::before,
.info-email::before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 24px;
    height: 24px;
    margin-right: 7px;
    margin-bottom: 3px;
}

.info-phone::before {
    background: url(../../public/img/icon-phone.svg) no-repeat 0 50%;
    background-size: cover;
    filter: invert(69%) sepia(64%) saturate(5394%) hue-rotate(127deg) brightness(98%) contrast(101%);
}

.info-email::before {
    width: 16px;
    height: 13px;
    background: url(../../public/img/icon-email.svg) no-repeat 0 50%;
    background-size: cover;
}

/* full screen map control */
.btn-fullscreen {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    bottom: 85px;
    background: #ffffff url(../../public/img/icon-fullscreen-open.svg) no-repeat center / 18px 18px;
    border: 0px none;
    margin: 10px;
    padding: 0;
    appearance: none;
    cursor: pointer;
    overflow: hidden;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;    
}

.btn-fullscreen:hover {
    background-image: url(../../public/img/icon-fullscreen-active.svg);
}

.btn-fullscreen.fs-close {
    background-image: url(../../public/img/icon-fullscreen-close.svg);
}

/* submit listing btn */
.btn-add-listing {
    position: fixed;
    right: 10px;
    bottom: 30px;
    z-index: 8;
    border: 0;
    padding: 8px 10px 8px 34px;
    background: #fff url(../../public/img/submit.svg) no-repeat 10px 50%;
    border-radius: 3px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    font-size: 12px/14px;
    font-weight: 500;
}

.listing-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px;
    background-color: rgba(0,0,0, 0.5);
    display: flex;
    z-index: 12;
}

.listing-box .form {
    margin: auto;
    min-width: 100%;
    /*margin-top: 15%;*/
}

.listing-box-close {
    position: absolute;
    top: -45px;
    left: 0;
    padding: 10px 0;
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 15px;
    cursor: pointer;
    border: 0;
    background-color: transparent;
}

.search-form .btn-primary {
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
}

.btn-primary,
.btn-secondary {
    border: 0;
    height: 50px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    background: rgb(10, 193, 142);
    color: #fff;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    width: 100%;
}

input.btn-primary.spinner {
    background-image: url("../../public/img/spinner.gif");
    background-repeat: no-repeat;
    background-position: center;
    text-indent: -9000px;
}

.btn-primary:hover,
.btn-secondary:hover {
    opacity: 0.9;
}

.btn-secondary {
    color: #565c69;
    background-color: transparent;
    border: 1px solid #ddd;
}

.listing-add {
    max-width: 580px;
    margin: auto;
    padding: 10px;
    background-color: #f6f6f6;
    border-radius: 3px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
}

.listing-add h2 {
    margin: 20px 0;
    text-align: center;
}

.listing-add p {
    padding: 10px 40px;
    line-height: 1.5;
}

.listing-form {
    display: flex;
    flex-wrap: wrap;
}

.listing-form-field {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    &.full {
        width: 100%;
    }
}

.listing-form-field > label {
    margin-bottom: 10px;
    display: block;
    font-weight: 600;
    font-size: 14px;
    color: #565c69;
}

.listing-form-field > .checkbox {
    vertical-align:middle;
    display: inline;
    margin-right: 10px;
    font-weight: normal;
    font-size: 14px;
    color: #565c69;
}


.listing-form-field .required {
    color: red;
}

.listing-box div.error {
    background-color: #ffae9f;
    padding: 10px;
    margin: 0 10px;
}

.form input.error,
.form label.error {
    background-color: #ffae9f;
    animation: shake 0.3s;
}

input[type="submit"] {
    -webkit-appearance: none;
}

@keyframes shake {
    0% { transform: scaleX(1) }
    25% { transform: scaleX(1.1) }
    75% { transform: scaleX(0.9) }
    100% { transform: scaleX(1) }
}

.listing-form-field input[type="text"] {
    padding: 14px 25px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    box-shadow: 0 1px 1px rgba(0,0,0, 0.05) inset;
    height: auto;
}

.listing-form-field select {
    width: 100%;
    height: 50px;
    padding: 14px 25px;
    border: 1px solid #ddd;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 3px;
    font-size: 15px;
}

.listing-form-field select:focus,
.listing-form-field input[type="text"]:focus {
    border: 1px solid #999;
    box-shadow: none;
    outline: none;
}

.listing-form-actions {
    padding: 10px;
    display: flex;
    flex: 1;
    margin: 0 -10px;
}

.listing-form-actions > button,
.listing-form-actions > input {
    width: 50%;
    margin: 10px;
    flex: 1;
    box-sizing: border-box;
    outline: none;
}

.listing-form-options {
    padding: 10px 0 20px;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    color: #565c69;
}

.listing-form-options a {
    text-decoration: none;
    color: rgb(10, 193, 142);
}

.gray-overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.center-aligned {
    display: block;
    justify-content: center;
    margin: auto;
}
.top-aligned {
    margin: 70px auto;
}

@media only screen and (max-width: 670px) and (orientation: portrait) {
  .cta a:hover {
    opacity: 1;
  }

  .phone {
    display: none;
  }
}
