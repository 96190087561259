#submit-form {
    input[type="text"] {
        padding: 14px 14px 14px 14px;
    }
}

.rounded {
    border-radius: 15px;
}

#website-error-message {
    font-size: 10px;
    color: red;
    padding: 0px;
}

.category-error {
    border-color: #ffae9f !important;
}
