.ad-wrapper {
    position: fixed;
    left: 50%;
    top: 120px;
    transform: translateX(-50%);
    z-index: 13;

    @media (min-width: 700px) {
        top: 120px;
        left: 50%;
        bottom: unset;
        max-width: 300px;
        transform: translateX(-50%);
    }

    @media (min-width: 1025px) {
        top: 90px;
    }

    iframe {
        width: 300px;
        height: auto;
        max-width: calc(100vw - 40px) !important;
        border-radius: 12px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }

    @media (max-width: 1040px) {
        &.hidden {
            display: none;
        }
    }

   
}