header {
  height: auto;
  padding: 30px 40px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;

  .logo {
    position: absolute;
    z-index: 1;
  }

  .logo img {
    display: block;
    width: 170px;
    height: 24px;
  }
}

.form {
  flex: 1;
  position: relative;
  transition: all 0.4s ease-out;
  transform: translateY(0);
  display: flex;
  justify-content: center;
  align-items: center;

  .back-button {
    width: 43px;
    height: 45px;
    min-width: 43px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    border: none;
    border-radius: 16px;
    background: #ffffff url(../../public/img/icon-back.svg) no-repeat center;
    cursor: pointer;
    box-shadow: 0px 20px 87px rgba(45, 50, 59, 0.0200938),
      0px 10.125px 37.9266px rgba(45, 50, 59, 0.03475),
      0px 4px 14.1375px rgba(45, 50, 59, 0.0520312),
      0px 0.875px 5.02969px rgba(45, 50, 59, 0.08);
  }

  .search-form {
    display: table;
    width: 100%;
    // margin: auto;
    // max-width: 50%;
    position: relative;
  }

  button.icon {
    cursor: pointer;
    box-shadow: none;
    width: 46px;
    height: 100%;
    border: none;
    opacity: 0.5;
    outline: none;

    &[type="reset"] {
      z-index: 1;
      width: 46px;
      height: 100%;
      background: #ddd;
      position: absolute;
      right: 46px;
      border: 0;
      opacity: 0.2;
      background: url(../../public/img/reset.svg) no-repeat 50%;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
  }

  &.shrink-by-panel {
    @media only screen and (min-width: 1040px){
      width: calc(100% - 375px);
      margin-left: 375px;
      margin-right: 143px;

      & .search-form {
        max-width: unset;
      }
    }  

  }

  input[type="text"] {
    width: 100%;
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    padding: 14px 90px 14px 45px;
    font: 500 15px -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
      "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    border: 0;
    background-color: #fff;
    -webkit-appearance: none;
    outline: none;

    &::-ms-clear {
      display: none;
    }
    &::placeholder {
      color: #b1b5bf;
    }
  }

  .submit-wrapper {
    position: absolute;
    right: 0;
    height: 100%;
    box-sizing: border-box;

    button[type="submit"] {
      background: url(../../public/img/icon-search.svg) no-repeat 50%;
      margin-left: 0;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  .filter-wrapper {
    text-align: left;
    position: absolute;
    left: 0;
    height: 100%;
    box-sizing: border-box;
    button {
      background: url(../../public/img/icon-filter.png) no-repeat 50%;
      background-size: 20px 15px;
      margin-left: 0;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .search-wrapper {
    display: table-cell;
    width: 100%;
    vertical-align: top;
    position: relative;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.35);
  }
}

  .results-wrapper {
    position: absolute;
    left: 0px;
    top: 100%;
    margin-top: 5px;
    background-color: #fff;
    width: 100%;
    padding: 10px 0;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15) !important;
  }

  .result-section-header {
    padding: 5px 25px;

    span {
      font-size: 0.9em;
      font-weight: 600;
    }
  }

  .bcom-result-item {
    display: flex;
    align-items: center;
    padding: 12px 25px;
    cursor: pointer;
    font-size: 14px !important;    
  }

  .bcom-result-icon {
    margin-right: 5px;
    height: 18px;
  }

  .separator {
    height: 2px;
    border-bottom: 2px solid #eee;
  }

  .google-result-item {
    padding: 2px 25px;
    cursor: pointer;
    font-size: 14px !important;    
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    font-size: 11px;
    color: #515151;
  }

  .google-result-marker {
    width: 15px;
    height: 20px;
    margin-right: 10px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    -webkit-background-size: 34px 34px;
    background-size: 34px;
    background: url(../../public/img/location.png) no-repeat 0 0 / 12px 15px;
  }
  
  .google-result-item:hover, .bcom-result-item:hover {
    background-color: #fafafa;
  }

.pac-container {
  margin-top: 5px;
  padding: 10px 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15) !important;
}

.pac-logo:after {
  display: none;
}

.pac-item {
  padding: 2px 25px;
  cursor: pointer;
  border: 0;
}

.pac-item * {
  font-size: 14px !important;
}

.pac-item .pac-icon {
  margin-right: 10px;
  background: url(../../public/img/location.png) no-repeat 0 0 / 12px 15px;
}

.pac-item-query {
  margin-right: 5px;
}

.map-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (max-width: 1040px) {
  .form {
    width: auto;
    float: none;
    padding: 0;
  }
  header {
    align-items: stretch;
    flex-direction: column;
    padding: 20px;

    .logo {
      position: static;
    }

    .logo img {
      margin: 0 auto 15px auto;
    }
  }

  .form .search-form {
    max-width: none;
  }
}
@media only screen and (max-width: 800px) {
  html {
    -webkit-text-size-adjust: 100%;
  }

  header.hidden {
    height: 20px;
    padding-bottom: 0;
  }

  header.hidden .form {
    transform: translateY(-250%);
  }
}
@media only screen and (max-width: 670px) {
  .form {
    margin-right: 0;
    // margin-top: 0;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }
  .form.opened {
    margin-left: 51px;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .form ul.retailers {
    margin-bottom: 0;
  }
  .form ul.retailers li {
    float: none;
  }
  .form h4 {
    margin: 15px 0;
    white-space: nowrap;
  }
  .form input[type="text"]:focus {
    border-bottom: 0;
  }
}
@media only screen and (min-width: 1040px) {
  .form {
    justify-content: flex-end;
    margin: 0 143px 0 350px;
    max-width: 800px;
  }   
}